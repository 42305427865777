import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import OrderButton from '../order-button'
import Description from '../description'

const ProductOne = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)
  const [price, setPrice] = useState(0)

  return (
    <div className="product-info">
      <div className="product-info__label">
        Технологія зварювання мідною стрічкою
      </div>
      <h3 className="title">
        {productItem.title}
      </h3>
      <ProductCount
        count={count}
        setCount={setCount}
        price={price}
        setPrice={setPrice}
        productId={productId}
      />
      <OrderButton order={{ count, price, productItem }} />
      <Description productItem={productItem}>
        <p>
          <span>Тип:</span>
          <span>Li-ion</span>
        </p>
        <p>
          <span>Ємність:</span>
          <span>8400 mAh</span>
        </p>
        <p>
          <span>Струм розряду:</span>
          <span>90A</span>
        </p>
        <p>
          <span>Робоча напруга:</span>
          <span>20,4 – 25,2V</span>
        </p>
        <p>
          <span>Вага:</span>
          <span>875 (/- 5) гр.</span>
        </p>
        <p>
          <span>Габарити корпусу (д. ш. в.)</span>
          <span>145 / 44 / 65 (/-2мм)</span>
        </p>
      </Description>
    </div>
  )
}

export default ProductOne