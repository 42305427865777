import productItems from './productsItems'

const productsPriceRange = {
  2: {
    1: 2990,
    2: 2870,
    3: 2800,
    4: 2750,
    5: 2700,
    6: 2650,
    7: 2600,
  },
  3: {
    1: 4100,
    2: 4050,
    3: 4000,
    4: 3950,
    5: 3900,
    6: 3850,
    7: 3800,
  }
}

const getProductPrice = (productId, count) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const priceRange = productsPriceRange[productItem?.priceRange]
  if (!priceRange) {
    return productItem.price
  }
  if (count >= 1 && count < 10) {
    return priceRange[1]
  } else if (count >= 10 && count < 50) {
    return priceRange[2]
  } else if (count >= 50 && count < 100) {
    return priceRange[3]
  } else if (count >= 100 && count < 250) {
    return priceRange[4]
  } else if (count >= 250 && count < 500) {
    return priceRange[5]
  } else if (count >= 500 && count < 1000) {
    return priceRange[6]
  } else if (count >= 1000) {
    return priceRange[7]
  } else {
    return productItem.price
  }
}

export {
  getProductPrice,
  productsPriceRange
}