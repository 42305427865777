import { useEffect } from 'react'
import './index.scss'
import { getProductPrice } from '../../products/productsPriceRange'

const ProductCount = ({ price, setPrice, count, setCount, productId }) => {

  useEffect(() => {
    setPrice(getProductPrice(productId, count))
  }, [productId, count])

  const handleInputChange = (e) => {
    const val = e.target.value
    if (val < 1) {
      setCount(1)
    } else {
      setCount(val)
    }
  }
  return (
    <div className="product-count">
      <p className="product-count__price">
        {Math.round((price || 0) * count)} грн
      </p>
      <div className="product-count__counter">
        <button
          onClick={() => setCount(count - 1)}
          disabled={count === 1}
        >
          -
        </button>
        <input
          value={count}
          onChange={handleInputChange}
        />
        <button
          onClick={() => setCount(count + 1)}
        >
          +
        </button>
      </div>
    </div>
  )
}

export default ProductCount