import './index.scss'
import SocialButtons from '../social-buttons'
import Menu from '../menu'
import {
  Link
} from 'react-router-dom'

const Header = ({ smDevice, mdDevice }) => {
  return (
    <div className="header" id="header">
      <Menu mdDevice={mdDevice} />
      <div className="container header__container">
        <div className="header__content">
          <h3 className="title title--light">SPASEnergy</h3>
          <p className="header__text">
            MOLICEL INR21700P42A Li-ion 6S2P 8400 mAh 90A
          </p>
          <p className="header__price">
            від 2600 грн
          </p>
          <div className="header__buttons-wrapper">
            <Link to="/product-1" className="button-main">
              Замовити
            </Link>
          </div>
          {!smDevice && (
            <SocialButtons />
          )}
        </div>
        <img
          className="header__bg-img"
          alt=""
          src={require('./img/battery.png')}
          srcSet={`${require('./img/battery@2x.png')} 2x, ${require('./img/battery@3x.png')} 3x`}
        />
      </div>
    </div>
  )
}

export default Header