import { useState } from 'react'
import './index.scss'

const Description = ({ children, productItem }) => {
  const [open, setOpen] = useState(true)

  return (
    <div className={`product-description ${open ? 'opened' : ''}`}>
      {productItem?.priceRange && (
        <div className="product-description__disclaimer">
          *При замовлені від 1000 шт., ціна {productItem?.price} грн за одиницю.
        </div>
      )}
      <div
        className="product-description__header"
        onClick={() => setOpen(!open)}
      >
        <p>
          Характеристики
        </p>
        <button className="product-description__arrow">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.1921 9.23047L15.9065 13.6879C16.3408 14.2089 15.9702 15 15.292 15L8.70803 15C8.02976 15 7.65924 14.2089 8.09346 13.6879L11.8079 9.23047C11.9079 9.11053 12.0921 9.11053 12.1921 9.23047Z"
              fill="#333333" />
          </svg>
        </button>
      </div>
      {open && (
        <div className="product-description__content">
          {children}
        </div>
      )}
    </div>
  )
}

export default Description