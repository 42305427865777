import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import OrderButton from '../order-button'
import Description from '../description'

const ProductEight = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState({})

  return (
    <div className="product-info">
      <h3 className="title">
        {productItem.title}
      </h3>
      <p className="text">
        Надішліть нам технічне завдання та отримайте варіанти рішення Вашого запиту
      </p>
      <OrderButton
        order={{ count, price: selectedProduct?.price, productItem, selectedProduct }}
      />
      <Description productItem={productItem}>
        <p>
          Відновлення (перепаковка) - заміна зношених елементів на нові.
        </p>
        <p>
          Доопрацювання (збільшення ємності) - збірка батарей на елементах 21700 і підключення їх паралельно до штатної акб.
        </p>
      </Description>
    </div>
  )
}

export default ProductEight