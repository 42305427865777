import './index.scss'
import {
  Link
} from 'react-router-dom'
import productsItems from './productsItems'

const Products = () => {
  return (
    <section className="products" id="products">
      <div className="container">
        <h3 className="title">Продукція</h3>

        <div className="products-cards">
          {productsItems.map((item) => {
            return (
              <Link to={item.link} className="products-cards__item" key={item.id} style={{ order: item.order || 0 }}>
                <img
                  className="products-cards__img"
                  src={require(`../../img/products/product-${item.id}/img-1.jpeg`)}
                  srcSet={`${require(`../../img/products/product-${item.id}/img-1@2x.jpeg`)} 2x`}
                />
                {item.new && (
                  <div className="products-cards__new-label">
                    Новинка!
                  </div>
                )}
                <div className="products-cards__content">
                  <h4 className="products-cards__title">
                    {item.title}
                  </h4>
                  <h4 className="products-cards__title products-cards__title--price">
                    {item.price ? (
                      `${item.priceRange ? 'від' : ''} ${item.price} грн`
                    ) : (
                      'індивідуально'
                    )}
                  </h4>
                  <Link to={item.link} className="button-main button-main--small">
                    Детальніше
                  </Link>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Products