import ProductOne from '../product-page/products-info/ProductOne'
import ProductTwo from '../product-page/products-info/ProductTwo'
import ProductThree from '../product-page/products-info/ProductThree'
import ProductFour from '../product-page/products-info/ProductFour'
import ProductFive from '../product-page/products-info/ProductFive'
import ProductSix from '../product-page/products-info/ProductSix'
import ProductSeven from '../product-page/products-info/ProductSeven'
import ProductEight from '../product-page/products-info/ProductEight'

const productsItems = [
  {
    title: 'Акумуляторна батарея SPASEnergy 6S2P (EVE)',
    link: '/product-5',
    id: 5,
    component: <ProductFive productId={5} />,
    price: 2600,
    priceRange: 2
  },
  {
    title: 'Акумуляторна батарея SPASEnergy 6S3P (EVE)',
    link: '/product-6',
    id: 6,
    component: <ProductSix productId={6} />,
    price: 3800,
    priceRange: 3
  },
  {
    title: 'Акумуляторна батарея SPASEnergy 6S2P (MOLICEL)',
    link: '/product-1',
    id: 1,
    component: <ProductOne productId={1} />,
    price: 2600,
    priceRange: 2
  },
  {
    title: 'Акумуляторна батарея SPASEnergy 6S3P (MOLICEL)',
    link: '/product-2',
    id: 2,
    component: <ProductTwo productId={2} />,
    price: 3800,
    priceRange: 3
  },
  {
    title: 'Акумуляторна батарея SPASEnergy 6S1P (MOLICEL)',
    link: '/product-3',
    id: 3,
    component: <ProductThree productId={3} />,
    price: 1800
  },
  {
    title: 'АКБ для РЕБ',
    link: '/product-7',
    id: 7,
    component: <ProductSeven productId={7} />,
    price: 46000
  },
  {
    title: 'Акумуляторні батареї для БпЛА літакового типу та НРК',
    link: '/product-4',
    id: 4,
    component: <ProductFour productId={4} />,
    price: ''
  },
  {
    title: 'Відновлення та доопрацювання АКБ для Mavic',
    link: '/product-8',
    id: 8,
    component: <ProductEight productId={8} />,
    price: ''
  },
]

export default productsItems
